<template>
  <div v-loading="loading">
    <en-table-layout toolbar pagination :tableData="pageData.data">
      <template slot="toolbar">
        <el-form-item label="时间范围">
          <el-date-picker
            style="width: 250px;"
            v-model="advancedForm.order_time_range"
            type="daterange"
            align="center"
            size="medium"
            :editable="false"
            unlink-panels
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="关键词">
          <el-input
            size="medium"
            v-model="advancedForm.keywords"
            placeholder="请输入关键词"
            clearable
          >
            <el-select
              slot="prepend"
              size="medium"
              v-model="advancedForm.type"
              placeholder="请选择类型"
              style="width: 110px"
            >
              <el-option label="订单编号" value="sn"></el-option>
              <el-option label="服务内容" value="service_name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>

        <div class="col-auto">
          <el-button @click="advancedSearchEvent" size="small" type="primary">
            搜索
          </el-button>
        </div>

        <div class="col"></div>

        <div class="col-auto">
          <el-button
            @click="submitImport"
            :loading="importLoading"
            size="small"
            type="primary"
          >
            导出服务订单
          </el-button>
        </div>
      </template>

      <template slot="table-columns">
        <!--订单编号-->
        <el-table-column prop="sn" label="订单编号" width="200"/>
        <!--服务内容-->
        <el-table-column prop="service_name" label="服务内容" min-width="200"/>
        <!--价格-->
        <el-table-column label="价格">
          <template slot-scope="scope">
            {{ scope.row.price | unitPrice('￥') }}
          </template>
        </el-table-column>
        <!--创建时间-->
        <el-table-column label="创建时间" width="150">
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate }}
          </template>
        </el-table-column>
        <!--开通时间-->
        <!--        需求132去掉开通时间-->
        <!--        <el-table-column label="支付/开通时间" width="150">-->
        <!--          <template slot-scope="scope">-->
        <!--            {{ scope.row.pay_time | unixToDate }}-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <!--状态-->
        <el-table-column label="状态" width="100">
          <template slot-scope="scope">
            {{ scope.row.pay_status === 'PAY_NO' ? '未付款' : '已付款' }}
          </template>
        </el-table-column>
        <!--操作-->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleOperateOrder(scope.row.id)">
              查看
            </el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no"
        :page-size="pageData.page_size"
        :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout"
        background
        :total="pageData.data_total"
      >
      </el-pagination>
    </en-table-layout>

    <x-dialog :proxy="detailDialog">
      <el-form label-width="200px">
        <el-form-item label="企业名称">
          {{ orderDetail.shop_name }}
        </el-form-item>
        <el-form-item label="订单编号">
          {{ orderDetail.sn }}
        </el-form-item>
        <el-form-item label="服务内容">
          {{ orderDetail.service_name }}
        </el-form-item>
        <el-form-item label="价格">
          {{ orderDetail.price }}
        </el-form-item>
        <el-form-item label="创建时间">
          {{ orderDetail.create_time | unixToDate }}
        </el-form-item>
        <!--        <el-form-item label="支付/开通时间">-->
        <!--          {{ orderDetail.pay_time | unixToDate }}-->
        <!--        </el-form-item>-->
        <el-form-item label="状态">
          {{ orderDetail.pay_status === 'PAY_NO' ? '未付款' : '已付款' }}
        </el-form-item>
      </el-form>
    </x-dialog>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import * as API_order from '@/api/order';
import {handleDownload} from '@/utils';
import {Foundation} from '@/../ui-utils';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';
import XDialog from '@/components/x-dialog/x-dialog';
import {$xDialog} from '@/components/x-dialog/dialog.proxy';

export default {
  name: 'orderList',
  components: {
    XDialog,
    EnTableLayout
  },
  computed: {
    ...mapGetters(['shopInfo'])
  },
  data() {
    return {
      detailDialog: $xDialog.create({
        title: '订单详情',
      }),
      /** 列表loading状态 */
      loading: false,
      /* 页面表格 */
      tableData: {},
      /* 弹窗表格  */

      tableData1: [],
      /** 订单详情数据 */
      orderDetail: {},
      importLoading: false,
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        ...this.$route.query,
        pay_status: 'PAY_YES',
        sType: 1
      },

      /** 列表分页数据 */
      pageData: [],

      /** 高级搜索数据 */
      advancedForm: {},

      /** 表格最大高度 */
      tableMaxHeight: document.body.clientHeight - 54 - 34 - 50 - 15
    };
  },
  mounted() {
    this.GET_OrderList();
  },
  activated() {
    delete this.params.market_enable;
    this.params = {
      ...this.params,
      ...this.$route.query
    };
    this.GET_OrderList();
    window.onresize = this.countTableHeight;
  },
  beforeRouteUpdate(to, from, next) {
    delete this.params.market_enable;
    this.params = {
      ...this.params,
      ...this.$route.query
    };
    this.GET_OrderList();
    next();
  },
  filters: {
    paymentTypeFilter(val) {
      return val === 'ONLINE' ? '在线支付' : '线下支付';
    }
  },
  methods: {
    /** 查看订单 */
    handleOperateOrder(id) {
      API_order.getServiceOrderdId(id).then(res => {
        this.loading = false;
        this.orderDetail = res;
        // console.log(this.orderDetail, 443333);
        this.detailDialog.display();
      });
    },
    /** 计算高度 */
    countTableHeight() {
      this.tableHeight = document.body.clientHeight - 54 - 35 - 50;
    },

    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_OrderList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_OrderList();
    },

    changepayment_type(data) {
      this.GET_OrderList();
    },

    /** 搜索事件触发 */
    searchEvent(data) {
      this.params = {
        ...this.params,
        keywords: data
      };
      delete this.params.order_status;
      Object.keys(this.advancedForm).forEach(key => delete this.params[key]);
      this.GET_OrderList();
    },

    /** 高级搜索事件触发 */
    advancedSearchEvent() {
      this.params = {
        ...this.params,
        ...this.advancedForm
      };
      if (this.advancedForm.type === 'sn') {
        this.params.sn = this.params.keywords;
        delete this.params.service_name;
      } else {
        this.params.service_name = this.params.keywords;
        delete this.params.sn;
      }
      delete this.params.type;
      delete this.params.start_time;
      delete this.params.end_time;
      if (this.advancedForm.order_time_range) {
        this.params.start_time =
          this.advancedForm.order_time_range[0].getTime() / 1000;
        this.params.end_time =
          this.advancedForm.order_time_range[1].getTime() / 1000;
      }
      delete this.params.keywords;
      delete this.params.order_time_range;
      this.GET_OrderList();
    },
    //导出
    submitImport() {
      this.importLoading = true;
      let orderFunc = 'getServiceOrder';
      let filterVals = [
        'sn',
        'service_name',
        'price',
        'create_time',
        // 'pay_time',
        'pay_status'
      ];
      let tHeaders = [
        '订单编号',
        '服务内容',
        '价格',
        '创建时间',
        // '开通时间',
        '状态'
      ];
      let params = {...this.params};
      params.page_no = 1;
      params.page_size = 10000;
      API_order[orderFunc](params)
        .then(res => {
          res.data.map((item, index) => {
            item.create_time = Foundation.unixToDate(
              item.create_time,
              'yyyy-MM-dd hh:mm:ss'
            );
            item.pay_time = Foundation.unixToDate(
              item.pay_time,
              'yyyy-MM-dd hh:mm:ss'
            );
            item.pay_status =
              item.pay_status === 'PAY_NO' ? '未付款' : '已付款';
          });
          handleDownload(res.data, tHeaders, filterVals, '服务订单');
          this.importLoading = false;
        })
        .catch(res => {
          this.importLoading = false;
        });
    },
    GET_OrderList() {
      this.loading = true;
      API_order.getServiceOrder(this.params).then(res => {
        this.loading = false;
        // this.tableData = res.data;
        this.pageData = res;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
